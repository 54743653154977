import * as React from 'react';
import engagement from './engagement.jpg';
import carParking from './car-parking.png';
import busPickup from './bus-pickup.png';
import greyleigh from './greyleigh-map.png';
import './App.css';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './authentication/config';
import { Link } from "react-router-dom";
import { Navbar } from './Navbar';

function Homepage() {

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h2>
          Celebrating the wedding of
        </h2>
        <h1>
          Lusiana and Paul
        </h1>
      </header>
      <div className="App-body">
        <img
          className={"main-photo"}
          src={engagement}
          alt="mt cook engagement"
        />
        <div className={"merege-details"}>
            <h2 className={"merege-date"}>
              <div>
                30
              </div>
              <div className={"merege-month"}>
                DECEMBER
              </div>
              <div>
                2024
              </div>
            </h2>
          <h4 className="merege-time">
            at two o'clock in the afternoon
          </h4>
          <h2>
            <a
              className="App-link"
              href="https://www.greyleigh.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GREYLEIGH KIAMA
            </a>
          </h2>
          <div style={{ marginTop: "4rem" }}>
            <a
                className="Map-link"
                href="https://maps.app.goo.gl/vYP2pSQR5HE32VrM6"
                target="_blank"
                rel="noopener noreferrer"
                >
              <img
                className={"map-photo"}
                src={greyleigh}
                alt="greyleigh map"
                />
            </a>
          </div>
          
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "40px" }}>
            <h2>
              Schedule
            </h2>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "start" }}>
              <ul>
                <li>1:30pm Guests Arrive</li>
                <li>2:00pm Ceremony Begins</li>
                <li>2:30pm Drinks and Canapes on the lawn</li>
                <li>5:00pm Dinner served</li>
                <li>9:45pm Guests Depart</li>
              </ul>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "40px" }}>
            <h2>
              Transport
            </h2>
            <h4>- Bus -</h4>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "start" }}>
              <ul>
                <li>Bus leaves from <strong>Kiama Station</strong> <em>strictly</em> 1:30pm</li>
                <li>Bus departs from Greyleigh 9:45pm</li>
              </ul>
            </div>
            <div>
              <img
                className={"main-photo"}
                src={busPickup}
                alt="bus pickup indicator"
              />
            </div>
            <h4>- Car -</h4>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "start" }}>
              <ul>
                <li>Please aim to arrive about 1:30pm, the ceremony must start at 2:00pm</li>
              </ul>
            </div>
            <div>
              <img
                className={"main-photo"}
                src={carParking}
                alt="car parking indicator"
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "40px" }}>
            <h2>
              Wishing Well
            </h2>
            <h4>We greatly appreciate any contribution you can make to our future</h4>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                  className={"white-button"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://www.paypal.com/pools/c/9aPBKuh0ny";
                  }}
                  >
                  Contribute
              </button>
            </div>
            <h4>Thank you for celebrating with us</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
